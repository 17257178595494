<template>
  <!-- Main Container -->
  <div class="font-sans">
    <!-- Header -->
    <SiteHeader />
    <!-- End of Header -->

    <WaBtn />

    <!-- Banner -->
    <MainBanner />
    <!-- End Banner -->

    <!-- Product-->
    <div class="py-10 bg-center bg-no-repeat bg-cover" style="background-image: url('/images/bg-product.jpg')">
      <div class="container py-4 mx-auto font-sans text-center text-gray-800">
        <h2 class="pb-2 text-4xl font-bold xl:text-5xl">Our Products</h2>
        <Product />
      </div>
    </div>
    <!-- End Of Product-->

    <!-- Why Choose Us -->
    <div class="py-8 bg-gray-800">
      <div class="container py-4 m-auto font-sans text-center text-white">
        <h2 class="pb-2 text-4xl font-bold xl:text-5xl">Why Choose Us</h2>
        <div class="flex flex-wrap justify-center pt-6 lg:max-w-3xl lg:mx-auto">
          <div class="w-1/2 p-2 font-semibold md:w-1/4" v-for="(item, i) in chooseUs" :key="i">
            <img :src="item.image" :alt="item.alt" class="w-1/2 m-auto md:w-24 md:mx-auto" />
            <p class="pt-4 text-lg font-semibold md:leading-tight sm:text-xl md:text-base">
              {{ item.alt }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- End Of Why Choose Us -->

    <!-- how to order -->
    <div class="py-8 bg-gray-100">
      <div class="container px-4 mx-auto">
        <h2 class="pb-2 text-4xl font-bold text-center text-gray-800 xl:text-5xl">
          How To Order
        </h2>
        <div class="flex flex-wrap pt-6 md:justify-center lg:max-w-4xl lg:mx-auto">
          <div v-for="(item, i) in quotation" :key="i"
            class="flex items-center justify-center w-1/2 p-2 lg:w-1/5 md:w-1/3">
            <div>
              <img :src="item.image" :alt="item.alt" class="w-16 mx-auto" />
              <h1 v-html="item.alt" class="pt-3 text-sm leading-tight text-center text-gray-800 lg:text-base">
                {{ item.alt }}
              </h1>
            </div>
            <i v-if="item.icon" class="text-red-900 fas fa-chevron-right"></i>
          </div>
        </div>
        <div class="pt-2 md:pt-6">
          <a href="#" v-scroll-to="{ el: '#element', duration: 1500 }">
            <div class="w-40 mx-auto text-sm bg-red-900 rounded-lg md:text-base lg:text-lg lg:w-48">
              <h1 class="py-2 text-center text-white">Request A Quote</h1>
            </div>
          </a>
        </div>
      </div>
    </div>
    <!-- end how to order -->

    <!-- Production -->
    <div class="container py-10 m-auto font-sans text-center text-gray-800">
      <h2 class="pb-2 text-4xl font-bold xl:text-5xl">Our Production</h2>
      <div class="flex flex-wrap md:justify-center">
        <div class="w-1/2 p-2 font-semibold md:w-1/4" v-for="(item, i) in production" :key="i">
          <img :src="item.image" :alt="item.alt" class="border-2 border-gray-800 border-solid" />
          <p v-html="item.alt" class="text-center">{{ item.alt }}</p>
        </div>
      </div>
    </div>
    <!-- Production End -->

    <div class="py-10 pb-5 bg-gray-800">
      <h2 class="pb-2 text-4xl font-bold text-center text-white xl:text-5xl">
        Our Clienteles
      </h2>
      <div class="container m-auto">
        <CarouselSlide />
      </div>
    </div>

    <!-- FAQ -->
    <div class="container py-4 m-auto font-sans">
      <h2 class="pb-2 text-4xl font-bold text-center text-gray-800 xl:text-5xl">
        FAQ
      </h2>
      <div class="container m-auto">
        <ListFaq />
      </div>
    </div>
    <!-- End Of FAQ -->

    <!-- About US -->
    <div class="py-10 text-white bg-gray-800">
      <h2 class="pb-2 text-4xl font-bold text-center xl:text-5xl">About Us</h2>
      <div class="container items-center m-auto md:flex md:justify-between">
        <div class="px-4 md:w-1/2">
          <img src="images/aboutBG.jpg" class="m-auto" />
        </div>
        <div class="w-full px-4 pt-4 text-center md:w-1/2 md:text-left md:leading-relaxed">
          <p>
            Established in 2003, Advanpress Concept Sdn Bhd was specialised in offset and digital
            printing. In 2008, our corporation engaged in PVC Membership Card and ID Card printing.
            In 2010, our company cooperate with Hong Kong Marcson International Smart Card Pte
            Ltd to develop the business of RFID technologies. In 2011, our corporation engage in
            designing and researching of RFID antennas. On January 2016, our company officially
            renamed as Advanpress Card & Services and joint venture with Marcson International
            Smart Card Pte Ltd to develop the local market of PVC Smart Card Printing and in 2023,
            we incorporated in Sdn Bhd and officially renamed as AdvanPress Marketing Sdn Bhd.
          </p>
        </div>
      </div>
    </div>
    <!-- End of About US -->

    <!-- Footer -->
    <div class="bg-center bg-no-repeat bg-cover" style="background-image: url('/images/bg-footer.jpg');">
      <div class="bg-gray-900 py-14 bg-opacity-70">
      <div class="container px-4 mx-auto">
        <div class="md:flex md:items-center">
          <!-- left side -->
          <div class="text-white md:w-1/2">
            <img src="/images/main-logo-white.png" alt="" class="w-56 mx-auto md:w-64 lg:w-1/2" />
            <div class="mt-4 text-center">
              <p class="text-lg font-bold md:text-xl lg:text-2xl ">AdvanPress Marketing Sdn. Bhd.</p>
            </div>
            <div class="flex items-start justify-center pt-6">
              <i class="fas fa-map-marker-alt"></i>
              <h1 class="pl-3 text-base font-semibold leading-tight lg:text-xl">
                No. 17, Jalan Penyelenggara U1/77 <br />
                Taman Perindustrian Batu 3,<br />
                40150 Shah Alam, Malaysia. <br /><br />

                <span class="underline"><a href="https://g.page/Advanpress?share"> View Map</a></span>
              </h1>
            </div>
            <div class="flex items-center justify-center pt-6">
              <h1 class="pl-3 text-lg font-semibold leading-tight lg:text-xl">
                <i class="fas fa-building"></i>
                <a href="tel:+60355106869"> 03-55106869 </a>
              </h1>
              <h1 class="pl-3 text-lg font-semibold leading-tight lg:text-xl">
                <i class="fas fa-mobile-alt"></i>
                <a href="tel:+60123292917"> 012-3292917 </a>
              </h1>
            </div>
            <div class="flex flex-wrap justify-center pt-3 m-auto text-center">
              <a href="https://waze.com/ul/hw281wbytx" target="_blank"><img src="images/waze-icon.svg"
                  class="w-10 m-auto mr-3" /></a>
              <a href="https://www.facebook.com/advanpress88/" target="_blank"><img src="images/fb-icon.svg"
                  class="w-10 m-auto" /></a>
            </div>
          </div>

          <!-- right side -->
          <div id="element" class="pt-6 md:w-1/2 md:pt-0">
            <h1 class="pb-3 text-xl font-bold text-center text-white">Get A Free Quote Now!</h1>
            <!-- feedback.activamedia.com.sg script begins here -->
            <iframe allowTransparency="true" style="min-height: 500px; height: inherit; overflow: auto" width="100%"
              id="contactform123" name="contactform123" marginwidth="0" marginheight="0" frameborder="0"
              src="//feedback.activamedia.com.sg/my-contact-form-5380600.html"></iframe>
            <!-- feedback.activamedia.com.sg script ends here -->
          </div>
        </div>
      </div>
    </div>
    </div>
    <!-- End of Footer -->

    <!-- CopyRight -->
    <div class="py-3 text-xs text-center text-black bg-white">
      <p>Website maintained by Activa Media. All Rights Reserved.</p>
    </div>
    <!--End Of CopyRight -->
  </div>
  <!-- End Of Main Container -->
</template>

<script>
// @ is an alias to /src
import SiteHeader from "@/components/SiteHeader.vue";
import Product from "@/components/Product.vue";
import CarouselSlide from "@/components/CarouselSlide.vue";
import ListFaq from "@/components/ListFaq.vue";
import { Carousel, Slide } from "vue-carousel";
import WaBtn from "@/components/WaBtn.vue";
import MainBanner from "@/components/MainBanner.vue"

export default {
  name: "Home",
  components: {
    SiteHeader,
    Product,
    CarouselSlide,
    ListFaq,
    Carousel,
    Slide,
    WaBtn,
    MainBanner
  },
  data() {
    return {
      chooseUs: [
        { image: "images/icon-1.png", alt: "Direct Factory Price" },
        { image: "images/icon-2.png", alt: "Local Manufacturer" },
        { image: "images/icon-3.png", alt: "Fast Delivery & Quality Printing" },
        { image: "images/icon-4.png", alt: "Order from 1 Piece & Above" },
      ],
      production: [
        {
          image: "images/production-01.jpg",
          alt: "Offset Printing Process <br> 胶印印刷",
        },
        {
          image: "images/production-02.jpg",
          alt: "Lamination Process <br> 覆膜合成",
        },
        {
          image: "images/production-03.jpg",
          alt: "Silkscreen Printing <br> 丝印工艺",
        },
        {
          image: "images/production-04.jpg",
          alt: "Punching Process <br> 冲卡过程",
        },
        {
          image: "images/production-05.jpg",
          alt: "Trimming Process <br> 修切材料",
        },
        {
          image: "images/production-06.jpg",
          alt: "Drilling Hole Process <br> 打孔过程",
        },
        {
          image: "images/production-07.jpg",
          alt: "Personalize Data Printing <br> 个性化打印",
        },
        {
          image: "images/production-08.jpg",
          alt: "Quality Control Process QC <br> 检验",
        },
      ],
      quotation: [
        {
          image: "/images/order-1.png",
          alt: "Send Enquiry & <br> Receive Quotation",
          icon: true,
        },
        { image: "/images/order-2.png", alt: "Make Payment", icon: true },
        {
          image: "/images/order-3.png",
          alt: "Provide Artwork <br>& Approve Sample",
          icon: true,
        },
        { image: "/images/order-4.png", alt: "Begin Production", icon: true },
        { image: "/images/order-5.png", alt: "Delivery", icon: false },
      ],
    };
  },
  mounted() {
    const gads = document.createElement("script");
    gads.setAttribute(
      "src",
      "https://www.googletagmanager.com/gtag/js?id=AW-828000104"
    );
    gads.async = true;
    document.head.appendChild(gads);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "AW-828000104");
    gtag("config", "UA-164209616-1");

    // gtag('event', 'conversion', {'send_to': 'AW-828000104/BaHzCNuunZ0BEOiO6YoD'});
  },
  methods: {
    yourMethod(label, dest) {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "AW-828000104");

      console.log("Test");
      gtag("event", "conversion", { send_to: label });
      window.location.href = dest;
    },
  },
};
</script>

<!--Custome Code CSS-->
<style>
/* Whatsapp svg */
#wa-btn {
  background-color: #4dc247;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
}

#wa-btn:hover {
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.3);
}

.background-color {
  background-color: rgba(80, 80, 80, 0.9);
}

/* Banner*/
.VueCarousel-dot-container {
  margin: 0 !important;
}

.txtshadow {
  text-shadow: 3px 2px 4px #000;
}

@media (min-width: 1280px){
 #element iframe{
  min-height: 600px !important;
 }
}
</style>
