<template>
    <VueFaqAccordion 
    :items="faq"
    />
</template>

<script>
import VueFaqAccordion from 'vue-faq-accordion'

export default {
  components: {
    VueFaqAccordion
  },
  data () {
    return {
      faq: [
          {
            title: 'What is your major products?',
            value: 'We supply PVC cards, Metal cards, RFID cards, Barcode cards, Magnetic Stripe cards, 3D cards, Transparent cards, Luggage Tag, RFID Adhesive label & Wristband',
          },
          {
            title: 'How long is the producttion lead time?',
            value: 'For order < 500pcs 3 working days, < 10K pcs 7 to 10 working days, 10K pcs & above 15 to 20days working days. Express service can be arrange for Urgent Order.',
          },
          {
            title: 'How about the delivery schedule?',
            value: '2 to 3 days for Klang Valley or West Malaysia by courier service. 3 to 5 days for East Malaysia by courier service. Self collection is also available for prior notice.',
          },
          {
            title: 'How about the packing?',
            value: 'For PVC cards: <br>Seal and wrap poly bags per 250pcs; <br>250pcs per paper box; <br>Seal and wrap poly bags per box; <br>500/1000/2000/5000pcs per carton; <br>Sea and wrap poly bags per carton; <br>English label with card name, qty, carton number, start and end serial number; <br>6kg per 1000pcs; <br><br>For Metal cards: <br>Individual poly bag per card; <br>100pcs per paper box; <br>100/200/500/1000pcs per carton; <br>1.5kg per 100pcs.'
          },
          {
            title: 'How about the payment condition? ',
            value: '50% depossit upon place order, balance payment to be clear before the delivery or postage. <br>We accept online TT payment, TNG Pay, Grab Pay, Boost Pay & Maybank QR Pay'
          },
          {
            title: 'How can I get a sample material or mock up? ',
            value: 'We can send our current samples as free. Clients need to pay for the sample postage. For actual sample mock up, we will charge RM50 for digital print sample (2 working days) or RM500 for Offset printing sample ( 7 working days). Sample fee can be waive for the order of 10K pcs above',
          },
          {
            title: 'How to place order? ',
            value: '1) Send us the enquiries by Email, WthatsApp or other way, <br>2) Quotation will be provide based on clients requirement. <br>3) Send us the artwork by email, WhatsApp or Wetransfer link. <br>4) We will send you jpg proof for approval <br>5) Make the payment or deposit <br>6) Start for production <br>7) Production feedback <br>8) Shipping feedback <br>9) Make the balance payment <br>10) shipping <br>11) Tracking till goods received ',
          },
          {
            title: 'What type of design file require? ',
            value: 'We need vector format design files such as AI, Eps or PDF. High Resolution of JPEG or PSD file are acceptable. (Min 300 dpi) For PVC cards, The standard card size is CR-80, 85mm*54mm which is same with the credit card size. But we need design file with 88mm*56mm as we need 2mm bleed parts per edge. For metal cards, we accept any custom size and shape.',
          },
          {
            title: 'Can you offer designing service? ',
            value: 'Yes, we have a wonderful and professional designer team. Design fee would be charge according to the clients requirement or concept proposed.'
          },
          {
            title: 'What’s your MOQ? ',
            value: 'MOQ is 1pcs for PVC cards with digital printing. MOQ 500pcs for PVC cards if offset printing or silk screen printing, and 3D cards. MOQ 100pcs for metal cards, carbon fiber cards & wood cards.'
          },
          {
            title: 'How is the accuracy of the color printing for mass production?',
            value: 'For Offset Printing, we can follow 90% +/- if the actual sample or Color Proof is provided. Same color of artwork may also vary from order to order even on the same material, reason being different from Offset Printing. Therefore, slight vary 10% +/- might happen from time to time. For digital printing, the color maybe differ from the actual sample or color proof. It would be more or less 20% tolerance. ',
          },
          {
            title: 'If I already paid to proceed, Can I cancel the order & request for refund?',
            value: 'If we totally havent start for the production, You can get the fully refund. Otherwise we will charge accordingly for the step that already proceeded.',
          },
          {
            title: 'Can you offer Express Service for Urgent order?',
            value: 'Yes, We do. Please contact us personally for further clarification.',
          },
          {
            title: 'How to become your Agent? Do I need to register or submit the SSM documents?',
            value: 'No registration or documents submittion is require. You can send in your order or enquiries anytime via eamail or WhatsAPP. We will offer you the Agent Price.'
          }
        ]
    }
  }
}
</script>

<style>
.faq-wrapper {
    max-width: 100% !important;
}
</style>
