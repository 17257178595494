<template>
    <div>
        <div class="flex flex-wrap md:justify-center">
            <div class="w-1/2 p-1 md:w-1/4">
                <div class="px-2 py-10 bg-white rounded-lg shadow-xl">
                    <VuePureLightbox thumbnail="/images/photoID-001.png" class="mx-auto"
                        :images="['images/photoID-001.jpg', 'images/photoID-002.jpg', 'images/photoID-003.jpg', 'images/photoID-004.jpg', 'images/photoID-005.jpg', 'images/photoID-006.jpg', 'images/photoID-007.jpg',]">
                    </VuePureLightbox>
                    <h3 class="py-4 text-base text-gray-800 bg-white lg:text-xl">Photo ID Card<br>人像证卡</h3>
                </div>
            </div>
            <div class="w-1/2 p-1 md:w-1/4">
                <div class="px-2 py-10 bg-white rounded-lg shadow-xl">
                    <VuePureLightbox thumbnail="/images/product-2.png" class=""
                        :images="['images/barcode-001.jpg', 'images/barcode-002.jpg', 'images/barcode-003.jpg', 'images/barcode-004.jpg',]">
                    </VuePureLightbox>
                    <h3 class="py-4 text-base text-gray-800 bg-white lg:text-xl">Barcode Card <br>条码卡</h3>
                </div>
            </div>
            <!-- swap -->
            <div class="w-1/2 p-1 md:w-1/4">
                <div class="px-2 py-10 bg-white rounded-lg shadow-xl">
                    <VuePureLightbox thumbnail="/images/product-14.png" class="" :images="['images/nfc-01.jpg']">
                    </VuePureLightbox>
                    <h3 class="py-4 text-base text-gray-800 bg-white lg:text-xl">NFC Business Card <br> NFC名片</h3>
                </div>
            </div>

            <div class="w-1/2 p-1 md:w-1/4">
                <div class="px-2 py-10 bg-white rounded-lg shadow-xl">
                    <VuePureLightbox thumbnail="/images/product-4.png" class=""
                        :images="['images/trans-001.jpg', 'images/trans-002.jpg', 'images/trans-003.jpg', 'images/trans-004.jpg',]">
                    </VuePureLightbox>
                    <h3 class="py-4 text-base text-gray-800 bg-white lg:text-xl">Transparent Card <br>透明卡</h3>
                </div>
            </div>
            <div class="w-1/2 p-1 md:w-1/4">
                <div class="px-2 py-10 bg-white rounded-lg shadow-xl">
                    <VuePureLightbox thumbnail="/images/product-5.png" class=""
                        :images="['images/luggage-001.jpg', 'images/luggage-002.jpg', 'images/luggage-003.jpg', 'images/luggage-004.jpg',]">
                    </VuePureLightbox>
                    <h3 class="py-4 text-base text-gray-800 bg-white lg:text-xl">Luggage Tag <br>行李吊牌</h3>
                </div>
            </div>
            <div class="w-1/2 p-1 md:w-1/4">
                <div class="px-2 py-10 bg-white rounded-lg shadow-xl">
                    <VuePureLightbox thumbnail="/images/product-6.png" class=""
                        :images="['images/hotel-001.jpg', 'images/hotel-002.jpg', 'images/hotel-003.jpg', 'images/hotel-004.jpg',]">
                    </VuePureLightbox>
                    <h3 class="py-4 text-base text-gray-800 bg-white lg:text-xl">Hotel Key Card <br>酒店房卡</h3>
                </div>
            </div>
            <div class="w-1/2 p-1 md:w-1/4">
                <div class="px-2 py-10 bg-white rounded-lg shadow-xl">
                    <VuePureLightbox thumbnail="/images/product-7.png" class=""
                        :images="['images/conference-001.jpg', 'images/conference-002.jpg', 'images/conference-003.jpg', 'images/conference-004.jpg',]">
                    </VuePureLightbox>
                    <h3 class="py-4 text-base text-gray-800 bg-white lg:text-xl">Conference Tag <br>展会卡</h3>
                </div>
            </div>
            <div class="w-1/2 p-1 md:w-1/4">
                <div class="px-2 py-10 bg-white rounded-lg shadow-xl">
                    <VuePureLightbox thumbnail="/images/product-8.png" class=""
                        :images="['images/ind-001.jpg', 'images/ind-002.jpg', 'images/ind-003.jpg', 'images/ind-004.jpg',]">
                    </VuePureLightbox>
                    <h3 class="py-4 text-base text-gray-800 bg-white lg:text-xl">Industrial Tag <br>工业吊牌</h3>
                </div>
            </div>
            <div class="w-1/2 p-1 md:w-1/4">
                <div class="px-2 py-10 bg-white rounded-lg shadow-xl">
                    <VuePureLightbox thumbnail="/images/product-9.png" class=""
                        :images="['images/fancy-001.jpg', 'images/fancy-002.jpg', 'images/fancy-003.jpg', 'images/fancy-004.jpg',]">
                    </VuePureLightbox>
                    <h3 class="py-4 text-base text-gray-800 bg-white lg:text-xl">Fancy Shape Card <br>异形卡</h3>
                </div>
            </div>
            <div class="w-1/2 p-1 md:w-1/4">
                <div class="px-2 py-10 bg-white rounded-lg shadow-xl">
                    <VuePureLightbox thumbnail="/images/product-10.png" class=""
                        :images="['images/metal-001.jpg', 'images/metal-002.jpg', 'images/metal-003.jpg', 'images/metal-004.jpg',]">
                    </VuePureLightbox>
                    <h3 class="py-4 text-base text-gray-800 bg-white lg:text-xl">Metal Card <br>金属卡</h3>
                </div>
            </div>
            <div class="w-1/2 p-1 md:w-1/4">
                <div class="px-2 py-10 bg-white rounded-lg shadow-xl">
                    <VuePureLightbox thumbnail="/images/product-11.png" class=""
                        :images="['images/frid-001.jpg', 'images/frid-002.jpg', 'images/frid-003.jpg', 'images/frid-004.jpg',]">
                    </VuePureLightbox>
                    <h3 class="py-4 text-base text-gray-800 bg-white lg:text-xl">RFID Key FOB Wristband <br>芯片钥匙扣腕带</h3>
                </div>
            </div>
            <div class="w-1/2 p-1 md:w-1/4">
                <div class="px-2 py-10 bg-white rounded-lg shadow-xl">
                    <VuePureLightbox thumbnail="/images/product-12.png" class=""
                        :images="['images/relief-001.jpg', 'images/relief-002.jpg', 'images/relief-003.jpg', 'images/relief-004.jpg',]">
                    </VuePureLightbox>
                    <h3 class="py-4 text-base text-gray-800 bg-white lg:text-xl">Relief Sculpture Card <br>浮雕卡</h3>
                </div>
            </div>
            <div class="w-1/2 p-1 md:w-1/4">
                <div class="px-2 py-10 bg-white rounded-lg shadow-xl">
                    <VuePureLightbox thumbnail="/images/product-13.png" class=""
                        :images="['images/dtf-01.jpg', 'images/dtf-02.jpg', 'images/dtf-03.jpg', 'images/dtf-04.jpg', 'images/dtf-05.jpg',]">
                    </VuePureLightbox>
                    <h3 class="py-4 text-base text-gray-800 bg-white lg:text-xl">DTF UV Label Printing <br>DTF UV 标签打印</h3>
                </div>
            </div>
            <!-- swap -->
            <div class="w-1/2 p-1 md:w-1/4">
                <div class="px-2 py-10 bg-white rounded-lg shadow-xl">
                    <VuePureLightbox thumbnail="/images/product-3.png" class=""
                        :images="['images/magnetic-001.jpg', 'images/magnetic-002.jpg', 'images/magnetic-003.jpg', 'images/magnetic-004.jpg',]">
                    </VuePureLightbox>
                    <h3 class="py-4 text-base text-gray-800 bg-white lg:text-xl">Magnetic Strip Card <br>磁条卡</h3>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VuePureLightbox from 'vue-pure-lightbox'
import styles from "vue-pure-lightbox/dist/VuePureLightbox.css";

export default {
    components: {
        VuePureLightbox,
    },

}
</script>

<style>
@media (min-width: 768px) {
    .lightbox__image img {
        width: 40%;
        margin: 0 auto;
    }
}
</style>