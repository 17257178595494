<template>
    <carousel :perPageCustom="[[0, 3], [575, 4], [991, 5]]" autoplayTimeout="5000" loop="true" class="text-center text-white font-sans">
        <slide>
            <div class="">
                <img class="w-4/5 border-solid border-4 border-gray-600 m-auto" src="images/client-01.jpg"> 
            </div>
        </slide>
        <slide>
            <div class="">
                <img class="w-4/5 border-solid border-4 border-gray-600 m-auto" src="images/client-02.jpg"> 
            </div>
        </slide>
        <slide>
            <div class="">
                <img class="w-4/5 border-solid border-4 border-gray-600 m-auto" src="images/client-03.jpg"> 
            </div>
        </slide>
        <slide>
            <div class="">
                <img class="w-4/5 border-solid border-4 border-gray-600 m-auto" src="images/client-04.jpg"> 
            </div>
        </slide>
        <slide>
            <div class="">
                <img class="w-4/5 border-solid border-4 border-gray-600 m-auto" src="images/client-05.jpg"> 
            </div>
        </slide>
        <slide>
            <div class="">
                <img class="w-4/5 border-solid border-4 border-gray-600 m-auto" src="images/client-06.jpg"> 
            </div>
        </slide>
        <slide>
            <div class="">
                <img class="w-4/5 border-solid border-4 border-gray-600 m-auto" src="images/client-07.jpg"> 
            </div>
        </slide>
        <slide>
            <div class="">
                <img class="w-4/5 border-solid border-4 border-gray-600 m-auto" src="images/client-08.jpg"> 
            </div>
        </slide>
        <slide>
            <div class="">
                <img class="w-4/5 border-solid border-4 border-gray-600 m-auto" src="images/client-09.jpg"> 
            </div>
        </slide>
        <slide>
            <div class="">
                <img class="w-4/5 border-solid border-4 border-gray-600 m-auto" src="images/client-10.jpg"> 
            </div>
        </slide>
        <slide>
            <div class="">
                <img class="w-4/5 border-solid border-4 border-gray-600 m-auto" src="images/client-11.jpg"> 
            </div>
        </slide>
        <slide>
            <div class="">
                <img class="w-4/5 border-solid border-4 border-gray-600 m-auto" src="images/client-12.jpg"> 
            </div>
        </slide>
        <slide>
            <div class="">
                <img class="w-4/5 border-solid border-4 border-gray-600 m-auto" src="images/client-13.jpg"> 
            </div>
        </slide>
        <slide>
            <div class="">
                <img class="w-4/5 border-solid border-4 border-gray-600 m-auto" src="images/client-14.jpg"> 
            </div>
        </slide>
        <slide>
            <div class="">
                <img class="w-4/5 border-solid border-4 border-gray-600 m-auto" src="images/client-15.jpg"> 
            </div>
        </slide>
        <slide>
            <div class="">
                <img class="w-4/5 border-solid border-4 border-gray-600 m-auto" src="images/client-16.jpg"> 
            </div>
        </slide>
        <slide>
            <div class="">
                <img class="w-4/5 border-solid border-4 border-gray-600 m-auto" src="images/client-17.jpg"> 
            </div>
        </slide>
        <slide>
            <div class="">
                <img class="w-4/5 border-solid border-4 border-gray-600 m-auto" src="images/client-18.jpg"> 
            </div>
        </slide>
        <slide>
            <div class="">
                <img class="w-4/5 border-solid border-4 border-gray-600 m-auto" src="images/client-19.jpg"> 
            </div>
        </slide>
        <slide>
            <div class="">
                <img class="w-4/5 border-solid border-4 border-gray-600 m-auto" src="images/client-20.jpg"> 
            </div>
        </slide>
        
    </carousel>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
export default {
    components: {
        Carousel,
        Slide,
    }
}
</script>

<style>

</style>