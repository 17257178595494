<template>
    <!-- Top Header -->
    <div class="items-center">
      <div class="container flex items-center justify-between py-5 m-auto sm:px-0 lg:px-4">
        <div class="w-1/2 md:w-auto">
          <img class="" src="images/logo.png" alt="Logo"/>
        </div>
        <div>
          <a href="tel:+60123292917" class="font-sans text-lg font-bold text-right text-gray-800" @click.prevent="yourMethod('AW-828000104/BaHzCNuunZ0BEOiO6YoD', 'tel:+60123292917')">
            <div>Call Us</div>
            <div class="flex flex-wrap justify-between">
              <img class="w-6 mr-1" src="images/phone-icon.svg"> 
              <p class="">012-3292917</p>
            </div>
          </a>
        </div>
      </div>
    </div>
    <!-- End of Top Header -->
</template>

<script>
    export default {
    methods: {
      yourMethod(label, dest) {

      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'AW-828000104');
    
      console.log("Test");
      gtag('event', 'conversion', {'send_to': label});
      window.location.href = dest;  
      }
    }
  }
    
</script>

<style scoped>

</style>