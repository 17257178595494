<template>
  <!-- <div
    class="bg-center bg-no-repeat bg-cover"
    style="background-image: url('/images/bg-main.jpg')"
  >
    <div class="container px-4 py-10 mx-auto lg:py-20">
      <div class="lg:relative md:flex md:items-center">
        <div class="md:w-1/2 md:order-2 lg:ml-auto">
          <img
            src="/images/bg-product.png"
            alt=""
            class="md:mx-auto lg:w-3/4"
          />
        </div>
        <div
          class="pt-3 text-3xl font-bold leading-tight text-center lg:absolute lg:text-6xl lg:w-2/3 md:text-5xl md:w-1/2 md:text-left md:order-1"
        >
          <h1 class="text-white txtshadow">Your One Stop</h1>
          <h1 class="text-white txtshadow">
            Card Printing Solutions Provider!
          </h1>
          <div class="pt-2 md:pt-6">
            <a
              v-if="!hideEnquiry"
              href="#"
              v-scroll-to="{ el: '#element', duration: 1500 }"
            >
              <div
                class="w-40 mx-auto text-sm bg-gray-800 rounded-lg md:mx-0 md:text-base lg:text-lg lg:w-48"
              >
                <h1 class="py-2 text-center text-white">Request A Quote</h1>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <div
    class="bg-no-repeat bg-cover bg-adjust"
    style="background-image:url('/images/bg-banner.jpg')"
  >
    <div class="container px-4 mx-auto">
      <div class="py-24 lg:py-32 xl:py-40">
        <div class="leading-tight text-center text-gray-900">
          <h1 class="text-3xl font-semibold lg:text-4xl">Your One Stop</h1>
          <h1 class="text-5xl font-bold lg:text-6xl text-blue-1">
            Card Printing
          </h1>
          <h1 class="text-2xl font-medium lg:text-3xl">Solutions Provider!</h1>
        </div>
        <div v-if="!hideEnquiry" class="pt-2 md:pt-6">
          <div
            class="w-40 mx-auto text-sm bg-gray-800 rounded-lg md:text-base lg:text-lg lg:w-48"
          >
            <a href="#" v-scroll-to="{ el: '#element', duration: 1500 }">
              <h1 class="py-2 text-center text-white">Request A Quote</h1>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.bg-adjust {
  background-position-x: 40%;
}
@media (min-width: 1500px){
  .bg-adjust{
    background-position-y: 80%;
  }
}
</style>
